<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        v-if="group"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-tochildren.png'"
        :letter="'a'"
        :selected="group && group.distribution_backup_one_children"
        :option="'Their children'"
        @selected="saveToChildren"
      >
        <template v-slot:text>
          <div v-html="toChildrenText"></div>
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        v-if="group"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-othergroup.png'"
        :letter="'b'"
        :selected="group && group.distribution_backup_one_remaining_group"
        :option="groupOption"
        @selected="saveToRemainingGroup"
      >
        <template v-slot:text>
          <div>{{ toRemainingGroupText }}</div>
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'DistributionGroup',
  mixins: [willStringHelpers, TextFormat],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge
  },
  props: [],
  created() {
    var string = this.$route.params.name
    this.groupName = string.replace(/%20/g, ' ')
    this.groupId = Number(this.$route.params.id)
  },
  beforeRouteUpdate(to, from, next) {
    var string = to.params.name
    this.groupName = string.replace(/%20/g, ' ')
    this.groupId = Number(to.params.id)
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('updateGroup', this.group)
    next()
  },
  computed: {
    group() {
      return this.$store.getters.groupsDistributionPrimary.find(
        (group) =>
          String(group.group_name).toLowerCase() ===
            String(this.groupName).toLowerCase() && group.id === this.groupId
      )
    },
    heading() {
      var string = ''
      if (this.group)
        string +=
          'If ONE of your ' +
          this.group.group_name +
          ' passed away before you, who should receive their share?'
      return string
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.peoplePrimary) number += this.peoplePrimary.length
      number += this.position + 1
      return number
    },
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    peoplePrimary() {
      return this.$store.getters.peopleDistributionPrimary
    },
    groupsPrimary() {
      return this.$store.getters.groupsDistributionPrimary
    },
    groups() {
      return this.$store.getters.groups
    },
    charities() {
      return this.$store.getters.charities
    },
    otherGroups() {
      return this.groupsPrimary.filter((group) => group.id !== this.group.id)
    },
    otherCharities() {
      return this.charities.filter((charity) => charity.distribution_primary)
    },
    isValidated() {
      if (
        this.group &&
        (this.group.distribution_backup_one_remaining_group ||
          this.group.distribution_backup_one_children)
      )
        return true
      return false
    },
    position() {
      return this.groupsPrimary.findIndex((group) => group.id === this.group.id)
    },
    forwardTo() {
      if (this.groupsPrimary && this.groupsPrimary.length > this.position + 1) {
        return `/distribution/primary_group/${
          this.groupsPrimary[this.position + 1].group_name
        }/${this.groupsPrimary[this.position + 1].id}`
      }
      if (this.charities.length) {
        // reset distribution backup table
        this.$store.commit('details', {
          distribution_all_die_dont_care: false,
          distribution_all_die_backup_table: false
        })
        return '/distribution/minors'
      }
      return '/distribution/all_fail'
    },
    backTo() {
      if (
        this.groupsPrimary &&
        this.groupsPrimary.length &&
        this.position - 1 >= 0
      ) {
        return `/distribution/primary_group/${
          this.groupsPrimary[this.position - 1].group_name
        }/${this.groupsPrimary[this.position - 1].id}`
      } else if (this.peoplePrimary && this.peoplePrimary.length)
        return `/distribution/primary_person/${
          this.peoplePrimary[this.peoplePrimary.length - 1].full_name
        }/${this.peoplePrimary[this.peoplePrimary.length - 1].id}`
      return '/distribution/beneficiaries'
    },
    toChildrenText() {
      if (this.group) {
        return (
          'If one of your ' +
          this.group.group_name +
          ' passes away before you their share would ' +
          'be split between their children (this includes any children born in the future). If when you ' +
          'pass away they do not have any children, the share would pass to the other ' +
          this.group.group_name +
          '.'
        )
      }
      return ''
    },
    toRemainingGroupText() {
      if (this.group) {
        return (
          'Selecting this option means that if one of your ' +
          this.group.group_name +
          ' passes away before ' +
          'you, their share will pass directly to the remaining ' +
          this.group.group_name +
          '.'
        )
      }
      return ''
    },
    groupOption() {
      if (this.group) return 'The remaining ' + this.group.group_name
      return ''
    }
  },
  data() {
    return {
      groupName: null,
      groupId: null,
      show: {
        intro: true,
        addModal: false
      },
      loading: false,
      subHeading:
        'As you have left a percentage of your estate to a group of people, you should consider what would happen if ' +
        'one of the people in the group were to pass away with or before yourself.'
    }
  },
  methods: {
    saveToChildren() {
      var group = Object.assign({}, this.group)
      group.distribution_backup_one_children = true
      group.distribution_backup_one_remaining_group = false
      this.$store.commit('groupAdd', group)
    },
    saveToRemainingGroup() {
      var group = Object.assign({}, this.group)
      group.distribution_backup_one_children = false
      group.distribution_backup_one_remaining_group = true
      this.$store.commit('groupAdd', group)
    }
  }
}
</script>
